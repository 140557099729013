<template>
    <div class="toolbar_index"  :style="{ left: left, right: right }">
        <ul class="toolbar">
            <li class="qq_box">
                <div class="wrap">
                    <i class="qq_icon t-icon t-icon-qq1"></i>
                    <span class="line"></span>
                </div>
                <p class="txt"><a target="_blank" href="http://wpa.qq.com/msgrd?v=3&uin=1289459644&site=qq&menu=yes">QQ<br/>咨询</a></p>
            </li>

            <li class="wechat_box">
                <div class="wrap">
                    <i class="wechat_icon t-icon t-icon-weixin"></i>
                    <span class="line"></span>
                </div>
                <p class="txt">微信<br/>咨询</p>

                <div class="toolbar_pop wechat_qrcode">
                    <div class="toolbar_wrap">
                        <div class="qrcode_txt">添加平台微信咨询</div>
                        <img class="qrcode_img" src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202112/17/%E9%A3%9E%E4%B9%A620211217-150645.png">
                        <img class="jiao" src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202112/14/%E9%A3%9E%E4%B9%A620211217-141239.png">
                    </div>
                </div>
            </li>
            
            <li class="serve_box">
                <i class="serve_icon iconfont icon-kefu"></i>
                <span class="line"></span>

                <div class="toolbar_pop serve_info">
                    <div class="toolbar_wrap">
                        <div class="info_title">在线客服热线</div>
                        <div class="info_num">18651648139</div>
                        <div class="info_line"></div>
                        <div class="info_title">服务时间</div>
                        <div class="info_num">工作日8:30-18:30</div>
                        <div class="info_num">节假日8:30-18:30</div>
                        <img class="jiao" src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202112/14/%E9%A3%9E%E4%B9%A620211217-141239.png">
                    </div>
                </div>
            </li>

            <li class="up_box" @click="top()">
                <i class="up_icon iconfont icon-zhiding"></i>
            </li>
        </ul>
    </div>
</template>
<script>
// import topnav from "@/components/Nav.vue";
// import foot from "@/components/Footer.vue";
// import topmenu from "@/components/Menu.vue";
// import topbread from "@/components/Bread.vue";
// import { getData, postData } from "@/api/user";
import {  toRefs, reactive,computed,watch} from "vue";
import { useStore } from "vuex";
// import { useRouter,useRoute } from "vue-router";
// import { ElMessage } from 'element-plus'
// import { Plus } from '@element-plus/icons'



export default {
    name: "detail",
    setup() {
        const store = useStore() 

        let state = reactive ({
            userid: store.state.userId,
            width: store.state.wd,
            left: 'auto',
            right: 0,
        })


        let init = () =>{
            let width = store.state.wd
            if(width <= 40) {
                state.left = 'auto'
                state.right = 0
            }else {
                state.left = Number(state.width + 1360) + 'px'
                state.right = 'auto'
            }

        }
        init()
        
        let wd = computed(() => { 
            return store.state.wd || 0;
        })

        watch(wd,(newValue) => {
            if(newValue) {
                if(newValue <= 40) {
                    state.left = 'auto'
                    state.right = 0
                }else {
                    state.left = Number(newValue + 1360) + 'px'
                    state.right = 'auto'
                }
            }
        })

        let top = () =>{
            window.scrollTo(100,0)
        }

        return {
            wd,
            top,
            ...toRefs(state)
        }
    },
};
</script>
<style lang="less">
.toolbar_index {
    position: fixed;
    top: 50%;
}
.toolbar {
    width: 52px;
    height: 261px;
    background: #FFFFFF;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    opacity: 1;
    border-radius: 7px;
    display: flex;
    flex-direction:column;
    align-items: center;
    
    .toolbar_pop{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 52px;
        background: #fff;
        height: auto;
        width: 132px;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        padding: 13px;
        .toolbar_wrap {
            position: relative;
            height: 100%;
            width: 100%;
            background: #fff;
        }

        .qrcode_txt {
            font-size: 12px;
            font-weight: 600;
            line-height: 14px;
            color: #666666;
            margin-bottom: 11px;
        }

        .qrcode_img {
            height: 107px;
            width: 107px;
        }

        .jiao {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: -32px;
            z-index:2;
        }

        .info_title {
            font-size: 12px;
            font-weight: 600;
            line-height: 14px;
            color: #666666;
            margin-bottom: 6px;
        }

        .info_num {
            font-size: 12px;
            font-weight: 600;
            line-height: 14px;
            color: #333333;
            margin-bottom: 3px;
        }
    }
    .serve_info {
        padding-bottom: 17px;
    }

    .info_line {
        height: 1px;
        width: 100%;
        background: #EAEAEA;
        margin: 14px 0 12px;
    }

    .qq_box {
        height: 66px;
        border-radius: 7px 7px 0px 0px;
        text-align: center;
        padding-top: 18px;
        cursor: pointer;
        .wrap {
            display: block;
        }
        .txt {
            display: none;
            font-size: 12px;
            font-weight: 600;
            line-height: 14px;
            color: #FFFFFF;
        }
    }
    .qq_box:hover {
        height: 70px;
        width: 100%;
        background: #39A4FF;
        .wrap {
            display: none;
        }
        .txt {
            display: block;
        }
    }
    .qq_icon {
        width: 30px;
        height: 30px;
    }
    .wechat_box {
        position: relative;
        height: 66px;
        text-align: center;
        padding-top: 18px;
        cursor: pointer;
        .wrap {
            display: block;
        }
        .txt {
            display: none;
            font-size: 12px;
            font-weight: 600;
            line-height: 14px;
            color: #FFFFFF;
        }
        .toolbar_pop{
            display: none;
        }

    
    }
    .wechat_box:hover {
        height: 70px;
        width: 100%;
        background: #00D528;
        .wrap {
            display: none;
        }
        .txt {
            display: block;
        }
        .toolbar_pop{
            display: block;
        }

    }
    .wechat_icon {
        width: 30px;
        height: 30px;
    }

    .serve_box {
        position: relative;
        height: 66px;
        text-align: center;
        padding-top: 18px;
        cursor: pointer;
        .serve_icon {
            font-size: 30px;
            color: #1C46B7;
        }
        .toolbar_pop{
            display: none;
        }
    }

    .serve_box:hover {
        height: 70px;
        width: 100%;
        background: #1C46B7;
        .serve_icon {
            color: #fff;
        }

        .line {
            display: none;
        }

        .toolbar_pop{
            display: block;
        }
    }


    .up_box {
        height: 66px;
        text-align: center;
        padding-top: 18px;
        cursor: pointer;
        .up_icon {
            font-size: 30px;
            color: #6666;
        }
    }

    .up_box:hover {
        height: 66px;
        width: 100%;
        .up_icon {
            color: #1C46B7;
        }
    }

    


    .line {
        text-align: center;
        display: inline-block;
        width: 32px;
        height: 1px;
        background: #EAEAEA;
    }
}
</style>