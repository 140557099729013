<template>
    <div class="menu" >
        <div class="index_outside">
            <div class="public_width" ref="wd">
                <ul class="menu_home d-flex">
                    <div class="home_classify" v-if="name == 'home'">
                        <el-menu
                            ref="menu"
                            default-active="1"
                            class="home_menu"
                            mode="horizontal"
                            :ellipsis="false"
                            @close="handleClose"
                            placement="left-end"
                        >
                            <el-sub-menu :popper-append-to-body="false" index="1" popper-class="home_sub">
                                <template #title><p class="menu_title"><i class="iconfont icon-syfl"></i>所有产品</p></template>
                                <el-sub-menu :popper-append-to-body="false"  
                                popper-class="home_child" :index="item.id.toString()" v-for="(item) in cate" :key="item.id">
                                <template #title>{{item.name}}</template>
                                    <el-menu-item :index="v.id.toString()" v-for="(v, k) in item.first" :key="k">
                                        <div class="classify_child">
                                            <h3 @click="to_product(v)">{{v.name}}</h3>
                                            <div class="child_wrap">
                                                <div class="child_box" v-for="(a, b) in v.second" :key="b">
                                                    <div class="d-center">
                                                    <div class="child_item"  @click="to_product(v,a)">{{ a.name }}</div> 
                                                    <i class="space" v-if="b < v.second.length - 1"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </el-menu-item>
                                </el-sub-menu>
                            </el-sub-menu>
                        </el-menu>
                    </div>
                    <div class="other_classify" v-else>
                        <el-menu
                            default-active="1"
                            :class="name == 'user' ? 'user_menu': 'other_menu'"
                            mode="horizontal"
                            :ellipsis="false"
                        >
                            <el-sub-menu index="1" :popper-append-to-body="false"  :popper-class="name=='user' ? 'user_sub': 'other_sub'">
                                <template #title><p class="menu_title"><i class="iconfont icon-syfl"></i>所有产品</p></template>
                                <el-sub-menu :popper-class="name=='user' ? 'user_child':'other_child'" :popper-append-to-body="false" :index="item.id.toString()" v-for="(item) in cate" :key="item.id">
                                    <template #title>{{item.name}}</template>
                                    <el-menu-item  :index="v.id.toString()"  v-for="(v,k) in item.first" :key="k">
                                        <div class="classify_child">
                                        <h3 @click="to_product(v)">{{v.name}}</h3>
                                        <div class="child_wrap">
                                            <div class="child_box" v-for="(a, b) in v.second" :key="b">
                                                <div class="d-center">
                                                <div class="child_item"  @click="to_product(v,a)">{{ a.name }}</div> 
                                                <i class="space" v-if="b < v.second.length - 1"></i>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </el-menu-item>
                                </el-sub-menu>
                            </el-sub-menu>
                        </el-menu>
                    </div>
                    <li class="nav_menu" :class="name == 'log' ? 'active': ''" >
                        <router-link to="/log">商品目录</router-link>
                    </li>
                    <li class="nav_menu" :class="name == 'smt' ? 'active': ''" @click="to_smt()">SMT</li>
                    <li class="nav_menu" @click="to_ebc()">E币兑换</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, reactive,toRefs,ref,onMounted,nextTick } from "vue";
import { getData } from "@/api/user";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { ElMessage } from 'element-plus'

export default defineComponent({
    props:{
        active: String,
    },
    emits:['get'],
    setup(props,ctx) {
        const store = useStore()
        const router = useRouter();
        const menu = ref(null)
        const wd = ref(null)

        let name = reactive(props.active)

        let state = reactive ({
            ali_oss: store.state.ali_oss,
            userid: store.state.userId,
            ebaina_ebc_url: store.state.ebaina_ebc_url,
            cate:[],
            classify: [],
        })

        let to_smt = () => {
            // window.open('https://b.ebaina.com/#/smt','_blank','')
            router.push({path: '/smt'})
        }

        let to_ebc = () => {
            if(state.userid) {
                window.open(state.ebaina_ebc_url,'_blank','')
            }else {
                store.commit("setLoginBox", true);
            }
        }

        let init = () => {
            getData({
                url: `api/home/category_group`,
                data: {},
            }).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    state.classify = data.data.list
                    if (state.classify.length > 0 ) {
                        state.classify.forEach((v)=> {
                            v.active = false
                            state.cate.push(v)
                        })
                    }
                }else {
                    ElMessage.error(data.data.msg);
                }
            });
        }
        init()

        let to_product = (item,v) => {
            let d = {}
            if( v && v.name !='') { // 二级菜单
                d = {
                    first:item.name,
                    second: v.name,
                    first_id: item.id,
                    second_id: v.id
                }
                router.push({
                    path: '/product',
                    query:d
                })
                window.scrollTo(100,0)
                
            }else {
                d = {
                    first:item.name,
                    first_id: item.id
                }
                router.push({
                    path: '/product',
                    query:d
                })
                window.scrollTo(100,0)
            }
            if(name == 'product') {
                ctx.emit('get',d);
            }
        }

        const handleClose = (key, keyPath) =>{
            if(name == 'home' && menu.value  && keyPath) {
                menu.value.open(keyPath);
            }
        }

        onMounted(()=>{
            nextTick(()=>{
                window.addEventListener('resize', ()=>{
                    let left = wd.value.getBoundingClientRect().left
                    store.commit('setWd',left)
                }, true)
            })
        })

        return {
            wd,
            menu,
            name,
            to_smt,
            to_ebc,
            ...toRefs(state),
            to_product,
            handleClose

        };
    },
});
</script>

<style lang="less" scoped>

    .menu {
        .index_outside {
            height: 45px;
            width: 100%;
            background: #EBEBEB;
            .nav_menu {
                line-height: 45px;
                font-size: 15px;
                color: #333;
                font-weight: 600;
                padding: 0 25px;
                cursor: pointer;
            }
            
            .nav_menu.active {
                color: #1b4fa8;
            }
        }

        .menu_home {
            height: 45px;
            
        }
    }


</style>


<style lang="less">
.menu_title {
    width: 100%;
    display: flex;
    .iconfont {
        margin-right: 22px;
        font-size: 12px;
    }
}
.el-menu.other_menu,.el-menu.user_menu,.el-menu.home_menu {
    width: 240px;
    margin-right: 12px;
    .el-sub-menu {
        padding: 0!important;
    }

    >.el-sub-menu>.el-sub-menu__title,.el-sub-menu__title:hover {
        position: relative;
        width: 240px;
        height: 45px;
        background: #092C8B;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16);
        display: flex;
        align-items:center;
        line-height: 44px;
        font-size: 15px;
        font-weight: 600;
        color: #ffffff;
        padding-left: 45px;
        .el-icon {
            display: none;
        }
        
    }
    

    >.el-sub-menu.is-active .el-sub-menu__title {
        color: #fff;
        border: none;
    }

    >.el-sub-menu.is-active>.el-sub-menu__title {
        border: none;
        color: #fff;
    }
}

.el-menu.user_menu {
    width: 200px;
    >.el-sub-menu>.el-sub-menu__title,.el-sub-menu__title:hover {
        width: 200px;
    }
}

.el-menu--horizontal.home_menu,
.el-menu--horizontal.user_menu,
.el-menu--horizontal.other_menu {
    >.el-sub-menu:hover .el-sub-menu__title { 
        color: #fff; 
    }
}

.el-popper.is-pure.home_sub,
.el-menu--horizontal.other_sub
.el-menu--horizontal.user_sub,
.el-menu--horizontal.home_sub{
    display: inline-block!important;
    z-index: 998!important;
}

.el-popper.is-pure.other_sub,
.el-popper.is-pure.user_sub,
.el-popper.is-pure.home_sub, {
    top: 45px!important;
    left: 0!important;
    background: transparent;
    border: none;
}

.el-menu--horizontal.other_sub,.el-menu--horizontal.user_sub {
    /*min-height: 396px;*/
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16);
    .el-menu {
        min-width: 240px;
        width: 240px;
        padding: 0;
    }

    >.el-menu {
        min-height: 395px;
        min-width: 240px;
        width: 240px;
        padding: 0;
        background: #1C46B7;
    }

    .el-sub-menu {
        line-height: 1;
        position: relative;
    }

    .el-icon {
        display: none;
    }

    .el-sub-menu__title,.el-sub-menu__title:hover {
        display: inline-block!important;
        font-size: 14px;
        height: 44px;
        width: 240px;
        line-height: 44px;
        color: #fff;
        padding: 0 10px 0 45px;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border: none;
        font-weight: 400;
        background: #1C46B7;

    }
    .el-sub-menu.is-opened .el-sub-menu__title, 
    .el-sub-menu__title:hover {
        background: #fff;
        color: #1C46B7!important;
    }    

    .el-popper.is-pure.other_child, .el-popper.is-pure.user_child{
        left: 240px!important;
        top:0!important;
        border: none!important;
        .el-menu {
            height: auto;
            width: 600px;
            box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.16);
            padding: 15px 24px 5px 30px;
        }

        .el-menu-item {
            display:block;
            height: auto;
            white-space: pre-wrap;
        }

        .el-menu-item:hover {
            background: #fff;
            
        }

        h3 {
            font-size: 13px;
            font-weight: 600;
            line-height: 17px;
            color: #1C46B7;
            margin-bottom: 20px;
            margin-top:10px;
        }

        .child_box {
            display: inline-block;
            margin-bottom: 20px;
            
        }

        .classify_child {
            line-height: 17px;
        }

        .space {
            display: inline-block;
            margin: 0 12px;
            height: 12px;
            width: 1px;
            background:#BCBCBC;
        }

        .child_item {
            display: inline-block;
            font-size: 13px;
            font-weight: 400;
            line-height: 17px;
            color: #666666;
            padding: 0;
        }

        .child_item:hover {
            background: #E3EBFF;
        }

    }

    .el-popper.is-pure.user_child {
        left: 200px!important;
        top:0!important;
    }
    .el-popper.is-light.other_child,.el-popper.is-light.user_child {
        border:none!important;
    }
}
.el-menu--horizontal.user_sub {
    .el-menu {
        min-width: 200px;
        width: 200px;
    }
    .el-sub-menu__title,.el-sub-menu__title:hover {
        width: 200px;
    }
}
.el-menu--horizontal.home_sub {
    /*min-height: 396px;*/
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16);
    .el-menu {
        min-width: 240px;
        width: 240px;
        padding: 0;
    }
    >.el-menu {
        min-height: 395px;
        min-width: 240px;
        width: 240px;
        padding: 0;
        background: #1C46B7;
    }

    .el-sub-menu {
        line-height: 1;
        position: relative;
    }

    .el-icon {
        display: none;
    }

    .el-sub-menu__title,.el-sub-menu__title:hover {
        display: inline-block!important;
        font-size: 14px;
        height: 43px;
        width: 240px;
        line-height: 44px;
        color: #fff;
        padding: 0 10px 0 45px;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border: none;
        font-weight: 400;
        background: #1C46B7;

    }
    .el-sub-menu.is-opened .el-sub-menu__title,
    .el-sub-menu__title:hover {
        background: #fff;
        color: #1C46B7!important;
    }
    

    .el-popper.is-pure.home_child {
        left: 240px!important;
        top:0!important;
        .el-menu {
            height: auto;
            width: 600px;
            box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.16);
            padding: 15px 24px 5px 30px;
        }

        .el-menu-item {
            display:block;
            height: auto;
            white-space: pre-wrap;
        }

        .el-menu-item:hover {
            background: #fff;
            
        }

        h3 {
            font-size: 13px;
            font-weight: 600;
            line-height: 17px;
            color: #1C46B7;
            margin-bottom: 20px;
            margin-top:10px;
        }

        .child_box {
            display: inline-block;
            margin-bottom: 20px;
            
        }

        .classify_child {
            line-height: 17px;
        }

        .space {
            display: inline-block;
            margin: 0 12px;
            height: 12px;
            width: 1px;
            background:#BCBCBC;
        }

        .child_item {
            display: inline-block;
            font-size: 13px;
            font-weight: 400;
            line-height: 17px;
            color: #666666;
            padding: 0;
        }

        .child_item:hover {
            background: #E3EBFF;
        }
    }

    .el-popper.is-light.home_child {
        border:none!important;
    }
}
</style>

